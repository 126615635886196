
import { Vue, Component } from 'vue-property-decorator'
import app from '@/store/modules/app'
import LoginLegacyForm from '@/components/LoginLegacyForm.vue'

@Component({
  components: { LoginLegacyForm },
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('login.TITLE')} - %s`,
    }
  },
})
export default class Login extends Vue {
}
